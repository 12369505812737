.banner {
  width: 99vw;
  position: relative;
  margin: 50px 0;

  video {
    width: 100%;
    position: relative;
  }
  h1 {
    font-size: 5rem;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 20%;
    color: #fff;
    font-weight: 700;
    @media screen and (max-width: 800px) {
      top: 50px;
      left: 5%;
    }
    @media screen and (max-width: 650px) {
      top: 50px;
      left: 5%;
      font-size: 2rem;
    }
  }
  img {
    width: 100vw;
    object-fit: contain;
  }
}
