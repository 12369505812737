.app {
  // @media screen and (max-width: 650px) {
  //   min-width: 120vw;
  //   padding: -50px;
  // }
  // @media screen and (max-width: 600px) {
  //   min-width: 130vw;
  //   padding: -75px;
  // }
  // @media screen and (max-width: 550px) {
  //   min-width: 135vw;
  //   padding: -100px;
  // }
  .toast-message {
    /* background: darkblue; */
    color: #fff;
    font-size: 2rem;
    width: 100vw;
    height: 100px;
    padding: 30px 20px;
    margin-right: 20px;
  }
  font-family: Roboto, Helvetica, Arial, sans-serif;
  .link {
    color: inherit;
    text-decoration: none;
  }
}
