.forgetPassword {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #fff;
  .register-container {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background-color: #ffff;
    padding: 100px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 612px) {
      width: 90%;
      padding: 15px;
    }
    .register-header {
      color: #777;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      @media screen and (max-width: 612px) {
        width: 100%;
        padding: 15px;
      }

      h1 {
        font-size: 40px;
        line-height: normal;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
      }
      p {
        a {
          text-decoration: none;
          color: #2879fe;
          transition: all 0.3s ease-in;
          text-align: center;
          margin-top: 15px;
          &:hover {
            color: #287afed4;
          }
        }
      }
    }
    form {
      width: 100%;
      .register-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        label {
          font-size: 30px;
          color: #777;
          margin: 15px 0;
        }
        input {
          // width: 500px;
          padding: 15px 10px;
          font-size: 20px;
          border: 1px solid #e1e6eb;
          border-radius: 5px;
        }
      }
    }
    button {
      margin-top: 30px;
      padding: 20px 35px;
      font-size: 30px;
      font-weight: 400;
      background-color: #2879fe;
      color: #ffff;
      border: 2px solid #fff;
      border-radius: 5px;
      width: 100%;
    }
  }
}
