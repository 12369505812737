.categories {
  display: flex;
  height: 80%;
  max-height: 80vh;
  gap: 10px;
  margin: 10px;
  // @media screen and (max-width: 1000px) {
  //   height: 70vh;
  // }

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .col-l {
    flex: 2;
  }

  .row {
    flex: 1;
    display: flex;
    gap: 10px;
    position: relative;
    overflow: hidden;

    button {
      position: absolute;
      min-width: 100px;
      width: fit-content;
      height: 50px;
      padding: 10px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      border: none;
      background-color: #ffff;
      border: 2px solid #2879fe;
      text-transform: uppercase;
      color: #2879fe;
      font-weight: 500;
      @media screen and (max-width: 500px) {
        font-weight: 400;
        padding: 5px;
        font-size: 12px;
        height: 30px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media screen and (max-width: 1000px) {
        // width: 70%;
        // height: 70%;
      }
    }
  }
}
