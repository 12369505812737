.cart {
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 999;
  background-color: white;
  padding: 20px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  width: 50vw;
  height: 100vh;
  @media screen and (max-width: 1000px) {
    min-width: 96vw;
  }

  h1 {
    // width: 90vw;

    margin-bottom: 30px;
    color: gray;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    img {
      width: 80px;
      height: 100px;
      object-fit: cover;
    }

    .details {
      h1 {
        font-size: 18px;
        font-weight: 500;
      }

      p {
        color: gray;
        margin-bottom: 10px;
        font-size: 14px;
      }

      .price {
        color: #2879fe;
      }
    }

    .delete {
      color: red;
      font-size: 30px;
      cursor: pointer;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
  }

  button {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
    border: none;
    background-color: #2879fe;
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
    a {
      text-decoration: none;
      color: white;
      font-weight: 500;
    }
  }

  .reset {
    color: red;
    font-size: 22px;
    cursor: pointer;
  }
}
