.products {
  padding: 30px 50px;
  display: flex;
  min-width: 100vw;
  .skeleton-image {
  width: 100%;
  height: 280px; 
  background-color: #ccc; 
    border: 1px solid  #cccccc;
    border-radius: 5px;
}

  @media screen and (max-width: 1300px) {
    padding: 10px;
  }

  h2 {
    color: gray;
    margin-top: 50px;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .left {
    flex: 1;
    position: sticky;
    height: 100%;
    top: 50px;
    border-right: 1px solid gray;

    @media screen and (max-width: 1300px) {
      position: relative;
      // width: 100vw;
      flex-direction: column;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 150px;
      box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
        rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
        rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

      main {
        width: 100%;
        .filterItem-time {
          width: 100%;
        }
        .filterItem-mixMax {
          width: 100%;
          padding: 0 5px;
          section {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }
      }
    }

    .filterItem-mixMax {
      min-height: 200px;
      display: flex;
      flex-wrap: wrap;
      width: 50%;

      section {
        margin: 30px 10 10px 0;
      }
      label {
        font-size: 30px;
      }

      input {
        padding: 10px 15px;
        font-size: 20px;
        width: 100%;
        border: 1px solid black;
      }
    }
    .filterItem-time {
      width: 50%;

      select {
        padding: 10px 15px;
        font-size: 20px;
        width: 100%;
      }
      @media screen and (max-width: 1300px) {
        width: 40%;
        padding: 0 5px;
        select {
          width: 100%;
          padding: 10px 15px;
          font-size: 15px;
          border: 1px solid black;
          background-color: #fff;
          option {
            width: 100px;
            padding: 10px 15px;
            font-size: 15px;
          }
        }
      }
    }

    button {
      width: 300px;
      padding: 15px 0;
      font-size: 20px;
      margin-top: 50px;
      background-color: #2879fe;
      color: white;
      border: 2px solid #fff;
    }
  }

  .right {
    flex: 5;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media screen and (max-width: 1300px) {
      .catImg {
        display: none;
      }
    }
    .catImg {
      width: 100%;
      height: 300px;
      object-fit: cover;
      margin-bottom: 50px;
    }
  }
  .productList {
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;

    .card {
      width: 280px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 50px;
      margin-right: 25px;

      .image {
        width: 100%;
        height: 400px;
        overflow: hidden;
        position: relative;

        &:hover {
          .secondImg {
            z-index: 2;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
        }

        .mainImg {
          z-index: 1;
        }

        span {
          position: absolute;
          top: 5px;
          left: 5px;
          background-color: white;
          color: teal;
          padding: 3px 5px;
          z-index: 3;
          font-weight: 500;
          font-size: 12px;
          display: flex;
          align-items: center;
        }
        .card-whichList {
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: white;
          color: teal;
          padding: 10px 10px;
          z-index: 3;
          font-weight: 500;
          font-size: 12px;
          display: flex;
          align-items: center;

          a {
            color: teal;
            text-align: left;
          }
        }
      }

      h2 {
        font-size: 16px;
        font-weight: 400;
      }

      .prices {
        display: flex;
        gap: 20px;

        h3 {
          font-size: 18px;
          font-weight: 500;

          &:first-child {
            color: gray;
          }
        }
      }
    }
  }
}
