.featuredProducts {
  margin: 100px 200px;
  @media screen and (max-width: 1449px) {
    margin: 50px 10px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    @media screen and (max-width: 1449px) {
      display: flex;
      flex-direction: column;
      .top-arrow {
        width: 100%;
      }
      .top-desc {
        display: none;
      }
    }
    .top-arrow {
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: #2879fe;
      padding: 10px 20px;
      border-radius: 20px;

      a {
        text-decoration: none;
        color: #fff;
        display: flex;
        align-items: center;

        h1 {
          flex: 2;
          text-transform: capitalize;
          color: #fff;
        }
        .arrowIcon {
          font-size: 3rem;
          margin-left: 15px;
          color: #fff;
        }
      }
    }
    .top-desc {
      width: 50%;

      p {
        flex: 3;
        color: gray;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    gap: 50px;
  }
}
.cards {
  width: 100%;
  display: flex;
  // justify-content: right;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px;

  &::-webkit-scrollbar {
    // display: none;
    background-color: gray;
    height: 15px;
    border-radius: 20px;
  }

  .card {
    width: 280px;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
    margin-right: 25px;
    object-fit: contain;
    max-width: 100%;
    max-height: 800px;

    .image {
      width: 100%;
      height: 400px;
      overflow: hidden;
      position: relative;

      &:hover {
        .secondImg {
          z-index: 2;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }

      .mainImg {
        z-index: 1;
      }

      span {
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: white;
        color: teal;
        padding: 3px 5px;
        z-index: 3;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
      .card-whichList {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: white;
        color: teal;
        padding: 10px 10px;
        z-index: 3;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;

        a {
          color: teal;
          text-align: left;
        }
      }
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
    }

    .prices {
      display: flex;
      gap: 20px;

      h3 {
        font-size: 18px;
        font-weight: 500;

        &:first-child {
          color: gray;
        }
      }
    }
  }
}
