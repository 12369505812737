.order-section {
  background-color: #fff;
  color: #fff;
}

.order-container {
  padding-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-row {
  margin-bottom: 1rem;
}

.order-heading {
  font-size: 2rem;
  color: #a8729a;
  margin-bottom: 0;
}

.order-name {
  color: #a8729a;
  font-size: 1.2rem;
  font-size: 2rem;
}

.order-card {
  border-radius: 30px;
  /* border: 1px solid #2879fe; */
  padding: 50px 20px 0 20px;
  min-height: 70vh;
  margin: 50px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.order-card-header {
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  padding: 0 30px;
}

.order-title {
  color: #a8729a;
  margin-bottom: 0;
  font-size: 1.2rem;
}

.order-voucher {
  color: #6c757d;
  margin-bottom: 0;
}

.order-card-body {
  padding: 1.5rem;
}

.order-item {
  display: flex;
  align-items: center;
  border: 1px solid #e1e6eb;
  border-radius: 20px;
  margin: 20px 0;
  min-width: 80vw;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.order-img-fluid {
  width: 100px;
  object-fit: contain;
  margin-right: 50px;
}
@media screen and (max-width: 1320px) {
  .order-item-details {
    flex-direction: column;
    margin-left: 50px;
  }
  .order-item-info-p {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 870px) {
  .order-item-details {
    margin: 0;
    text-align: center;
  }
  .order-item-info-p {
    width: 100%;
  }
  .order-item {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .order-img-fluid {
    width: 200px;
  }
  .order-card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.order-item-details {
  display: flex;
  width: 100%;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
}

.order-item-name {
  margin-bottom: 0;
}

.order-item-info {
  margin-bottom: 0;
  font-size: 2rem;
  color: #6c757d;
}
.order-item-info-p {
  /* color: #6c757d; */
  margin-bottom: 0;
  font-size: 2rem;
  text-transform: capitalize;
  background-color: #2879fe;
  padding: 15px 20px;
  height: 100%;
}
.order-item-price {
  margin-bottom: 0;
}

.order-hr {
  background-color: #2879fe;
  opacity: 1;
}

.order-total {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.order-total-label {
  font-weight: bold;
  margin-bottom: 0;
  color: #6c757d;
}
.order-total-value {
  font-weight: bold;
  margin-bottom: 0;
  color: #6c757d;
  text-transform: uppercase;
}
.order-total-value {
  margin-bottom: 0;
}

.order-info {
  display: flex;
  justify-content: space-between;
}

.order-info-label {
  margin-bottom: 0;
  color: #6c757d;
}

.order-info-value {
  margin-bottom: 0;
}

.order-card-footer {
  background-color: #2879fe;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1.5rem;
}

.order-total-paid {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
}

.order-total-amount {
  font-size: 2rem;
  margin-bottom: 0;
}
